<template>
  <div class="nofind">
    <img
      src="https://farmers.oss-cn-shenzhen.aliyuncs.com/images/PCqXhaglyBxiIy01RHjcIB8M445Ho5V0MGO2q7MF.jpg"
      alt=""
    />
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.nofind {
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
